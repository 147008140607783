<template>
    <b-card>
      <div class="d-flex justify-content-between align-items-center w-100 mb-1">
        <h3>حول التطبيق</h3>
        <div>
          <b-button
            variant="primary"
            v-b-tooltip.hover.v-info.top
            v-b-toggle.collapse-2
          >
            <feather-icon icon="editIcon" /> تعديل</b-button
          >
        </div>
      </div>
      <hr />
      <div v-if="loading">
        <b-skeleton
          v-for="n in 8"
          :key="n"
          animation="wave"
          width="85%"
        ></b-skeleton>
      </div>
      <div v-else v-html="about[0].content"></div>
      <hr />
      <b-collapse id="collapse-2" class="mt-2">
        <form-quill v-model="aboutText" />
        <div class="d-flex justify-content-end">

          <loader-btn
                label="حفظ"
                variant="primary"
                :loading="loading"
                @click="getUpdateAbout({id:about[0].id,content:aboutText})"
              >
              </loader-btn>
        </div>
      </b-collapse>
    </b-card>
  </template>
  
  
  <script>
  import {
    BCard,
    BButton,
    VBTooltip,
    BSkeleton,
    BCollapse,
    VBToggle,
  } from "bootstrap-vue";
  import { mapActions, mapGetters } from "vuex";
  import FormQuill from "@/components/form-quill/index.vue";
  import LoaderBtn from "@/components/loader-btn.vue";
  
  export default {
    components: {
      BCard,
      BButton,
      BSkeleton,
      VBTooltip,
      BCollapse,
      VBToggle,
      FormQuill,LoaderBtn
    },
    directives: {
      "b-tooltip": VBTooltip,
      "b-toggle": VBToggle,
    },
    data() {
      return {
        aboutText:null,
      };
    },
    methods: {
      ...mapActions("settings/aboutManaraApp", ["getAbout","getUpdateAbout"]),
    },
    computed: {
      ...mapGetters("settings/aboutManaraApp", ["about", "loading"]),
    },
    watch:{
      about(val){
          this.aboutText = val[0].content
      }
    },
    created() {
       this.getAbout();
    },
  };
  </script>